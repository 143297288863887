import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environments/environment';
import { CATEGORY } from '@tokens/category.token';
import { AvailableStrategyField } from '@type/strategy.type';
import { joinUrls } from '@utils/urls';
import { Observable, filter, first, map, switchMap } from 'rxjs';

@Injectable()
export class StrategyFieldsService {
  private http = inject(HttpClient);

  private category = inject(CATEGORY);

  get(): Observable<AvailableStrategyField[]> {
    const { categories, strategies, available_fields } = environment.urls;
    return this.category.asObservable.pipe(
      filter(Boolean),
      first(),
      switchMap(({ id }) =>
        this.http
          .post<{ fields: AvailableStrategyField[] }>(joinUrls(categories, String(id), strategies, available_fields), {
            category_id: this.category.value()!.id,
          })
          .pipe(map(({ fields }) => fields)),
      ),
    );
  }
}
